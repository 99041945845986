<template>
    <div class="grid">
        <div class="field col-12">
            <label for="nome">Nome</label>
            <InputText
                id="nome"
                type="text"
                v-model="nome"
                :class="(fieldsError.some(v => v === 'nome'))?'w-full p-invalid':'w-full'"
                maxlength="50"
            />
        </div>
        <div class="field col-6 mb-0">
            <label for="publicado">Publicado</label>
            <InputSwitch 
                id="publicado" 
                v-model="publicado"
                :class="(fieldsError.some(v => v === 'publicado'))?'p-invalid':''"
            />
        </div>
        <div class="field col-6 mb-0 text-right">
            <label></label>
            <Button label="Gravar" class="p-button-success p-button-sm" icon="pi pi-check" @click="gravar"></Button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        categoria: Object,
    },
    emits: [
        "save"
    ],
    data() {
        return {
            novo: true,

            // 1.0 Validação
            fieldsError: [],

            // 2.0 Campos Formulário
            nome: "",
            publicado: true,
        }
    },
    watch: {
        $props: {
            handler() {
                const val = this.categoria;

                if(typeof val == "object" && val != null) {
                    this.novo = false;

                    this.nome = val.nome;
                    this.publicado = val.publicado;
                } else {
                    this.novo = true;
                    
                    this.nome = "";
                    this.publicado = true;

                    this.fieldsError = [];
                }
                
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {

        validateForm: function() {

            this.fieldsError = [];

            if (this.nome == "") {
                this.fieldsError.push("nome");
            }

            if (this.fieldsError.length) {
                this.$toast.add({
                    severity: "error",
                    summary: "ERRO!",
                    detail: "Preencha corretamente todas as informações!",
                    life: 5000,
                });

                return false;
            }

            return true;
        },

        gravar: function() {
            if(this.validateForm()) {
                
                let categoria;

                if(this.novo) {
                    categoria = {};
                } else {
                    categoria = this.$utils.getStdObject(this.categoria);
                }

                categoria.nome = this.nome;
                categoria.publicado = this.publicado;
                categoria.idLoja = this.$auth.usuario.loja.id
                this.$emit("save", {value: categoria});

            }
        },

    },
}
</script>